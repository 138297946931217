// ** Initial State
const initialState = {
    profileEdit: {},
    profile: {}
}
const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROFILE_ITEM':
            return {
                ...state,
                profile: action.data
            }
        case 'GET_PROFILE_DETAIL':
            return action.data
        case 'GET_PROFILE_EDIT':
            return {
                ...state,
                profileEdit: action.data
            }

        default:
            return state
    }
}

export default profileReducer
