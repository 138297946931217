// ** Initial State
const initialState = { rooms: {}, webex:{} }

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROOMS':
            return {
                ...state,
                rooms: action.data
            }
        
            case 'WEBEX_ROOMS':
                return {
                    ...state,
                    webex: action.data
                }
        default:
            return state
    }
}

export default meetingReducer
