/* global AUTHORIZATION */
/* global IISIGHT_BASE_URL */

import axios from 'axios'

export const getApiBaseUrl = () => {
  if (IISIGHT_BASE_URL) {
    return IISIGHT_BASE_URL + "/";
  }
  if (window.IISIGHT_OPTIONS && window.IISIGHT_OPTIONS.domain) {
    return window.IISIGHT_OPTIONS.domain + "/";
  }
  return '/';
}

axios.defaults.baseURL = getApiBaseUrl()
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'authorization': AUTHORIZATION,
}