// ** Initial State
const initialState = {
    branding: null
}

const userBrandingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BRANDING_CONFIGURE':
            return {
                ...state,
                branding: action.data
            }
        default:
            return state
    }
}

export default userBrandingReducer