// ** React Imports
import { useState, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// ** Intl Provider Import
import { IntlProvider } from 'react-intl'
import { updateAccount } from 'redux/actions/profile'
import { updateUserData } from 'utility/Utils'
// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesTr from '@src/assets/data/locales/tr.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...userMessagesEn },
  de: { ...userMessagesDe },
  fr: { ...userMessagesFr },
  tr: { ...userMessagesTr }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const { profile } = useSelector( state => state.user )
  
  const langObj = {
    en: 'English',
    tr: 'Türkçe'
    // de: 'German',
    // fr: 'French',
    // pt: 'Portuguese'
  }
  let currentLocale = 'en';
  const localUserData = JSON.parse(localStorage.getItem('userData')) || {}
  if (localUserData?.currentLanguage && langObj[localUserData.currentLanguage ]) {
    currentLocale = localUserData.currentLanguage;
  }

  const [locale, setLocale] = useState(currentLocale)
  const [messages, setMessages] = useState(menuMessages[currentLocale])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
    dispatch(updateAccount({ id: localUserData.id }, { language: lang })).then(res => {
      if (res.status === 200) {

        updateUserData('currentLanguage', lang)

        dispatch({
          type: 'SET_USER_DATA',
          data
        })
      }
    }).catch(err => {
    })
  }

  return (
    <Context.Provider value={{ langObj, locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
