import { DEBOUNCE_TIMER } from "../constants"
import { useEffect, useState, useRef } from 'react'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys( obj ).length === 0

export const isEmptyData = data => {
  if(Array.isArray(data) && data.length) {
    return false;
  }
  if(typeof(data) === 'object' && Object.keys(data).length) {
    return false;
  }
  return true;
}

// ** Returns K format from a number
export const kFormatter = num => ( num > 999 ? `${( num / 1000 ).toFixed( 1 )}k` : num )

// ** Converts HTML to string
export const htmlToString = html => html.replace( /<\/?[^>]+(>|$)/g, '' )

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = ( value, formatting = { month: 'short', day: 'numeric', year: 'numeric' } ) => {
  if ( !value ) return value
  return new Intl.DateTimeFormat( 'en-US', formatting ).format( new Date( value ) )
}

// ** Returns short month of passed date
export const formatDateToMonthShort = ( value, toTimeForCurrentDay = true ) => {
  const date = new Date( value )
  let formatting = { month: 'short', day: 'numeric' }

  if ( toTimeForCurrentDay && isToday( date ) ) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat( 'en-US', formatting ).format( new Date( value ) )
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem( 'userData' )
export const getUserData = () => JSON.parse( localStorage.getItem( 'userData' ) )

export const isFeatureEnabled = (org, feature) => {
  if (org && org.length != 0) {
    return feature in org.features && org.features[feature];
  }
  return false;
}

export const canUserAudit = (userData, selectedOrganizationId) => {

  if (selectedOrganizationId) {
    const selectedOrganization = userData?.organizations.find(org => org.id === selectedOrganizationId);
    const userRole  = selectedOrganization?.userRole;

    if (userRole === 'owner' || userRole === 'manager' || userRole === 'auditor') {
      return true;
    }
  } else {
    for (let i = 0; i < userData?.organizations?.length; i++) {
      const org = userData.organizations[i];
      if (org.userRole == 'owner' || org.userRole == 'manager' || org.userRole == 'auditor') {
        return true;
      }
    }
  }

  return false;
};

/**
 * 
 */
export const getInactivePayment = () => JSON.parse( localStorage.getItem( 'inactivePayment' ) )

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  // if (userRole === 'Admin') return '/home'
  // if (userRole === 'Client') return '/access-control'
  return '/home'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ( {
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
} )

/* Greetings component trans boolean to text */
export const verifySelectedType = ( textSelected, videoSelected, youtubeSelected ) => {
  if ( textSelected && !videoSelected && !youtubeSelected ) {
    return 'text';
  } else if ( videoSelected && !textSelected && !youtubeSelected ) {
    return 'video';
  } else if ( youtubeSelected && !videoSelected && !textSelected ) {
    return 'youtube';
  } else if ( textSelected && videoSelected ) {
    return 'video_w_text';
  } else if ( textSelected && youtubeSelected ) {
    return 'youtube_w_text';
  }
};

export const transformSelectedType = ( selectedType, setTexGreetingsSelected, setVideoGreetingsSelected, setYoutubeGreetingsSelected ) => {

  switch ( selectedType ) {
    case 'text':
      setTexGreetingsSelected( true );
      setYoutubeGreetingsSelected( false );
      setVideoGreetingsSelected( false );
      break;
    case 'video':
      setVideoGreetingsSelected( true );
      setTexGreetingsSelected( false );
      setYoutubeGreetingsSelected( false );
      break;
    case 'youtube':
      setYoutubeGreetingsSelected( true );
      break;

    case 'video_w_text':
      setTexGreetingsSelected( true );
      setVideoGreetingsSelected( true );
      setYoutubeGreetingsSelected( false );
      break;

    case 'youtube_w_text':
      setTexGreetingsSelected( true );
      setYoutubeGreetingsSelected( true );
      setVideoGreetingsSelected( false );
      break;

    default: {
      setTexGreetingsSelected( false );
      setYoutubeGreetingsSelected( false );
      setYoutubeGreetingsSelected( false );
    }

  }
}

// ** localData usage is for the purpose of signUp and it is removed once signUp register is successful.
export const updateLocalStorage = (prop , value) => {
  const localData = JSON.parse(localStorage.getItem('localData')) || {}
  localData[prop] = value;
  localStorage.setItem('localData', JSON.stringify(localData))
}

export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

export const compare = (h1, h2) => {
  if (h1.label < h2.label) {
      return -1;
  }
  if (h1.label > h2.label) {
      return 1;
  }
  return 0;
}

export const formatAmount = (amount, currency) => {
  return <span style={{textDecoration:'line-through'}}>{amount} {currency}</span>
}

export const checkRoutePermission = ( userRole, organizations = [] ) => {
  const organizationItem = organizations.find(
    org => org.userRole === 'owner' || org.userRole === 'manager'
  ) || {};
  const organizationRole = organizationItem?.userRole || '';

  const organizationFeatures = organizationItem?.features || {};
  const permissionsData = {};

  if (userRole !== 'admin') {
    if ( !( organizationRole !== 'owner' && organizationRole !== 'manager' ) ){
      if ( organizationFeatures?.cname ) {
          permissionsData['cname'] = true;
      } 
      if ( organizationFeatures?.hubspot ) {
          permissionsData['hubspot'] = true;
      }
      if ( organizationFeatures?.clearbit ) {
          permissionsData['clearbit'] = true;
      }
    } 
  } else {
    permissionsData['all'] = true;
  }

  return permissionsData;
}

let timer;
export const debounce = (callback, time = DEBOUNCE_TIMER) => {
  const debouncedFunction = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      callback();
    }, time);
  };

  return debouncedFunction;
}

export const getTranslationId = (id) => {
  switch (id) {
    case 'Subscription Updated':
      return "Dashboard.OrganizationDashboard.Billing.SubscriptionUpdated";
    case 'Subscription Cancelled':
      return "Dashboard.OrganizationDashboard.Billing.SubscriptionCancelled";
    case 'Your credit card has been updated':
      return "Dashboard.OrganizationDashboard.Billing.CreditCardUpdated";
    case 'Please provide credit card information':
      return "Dashboard.OrganizationDashboard.Billing.ProvideCreditCard";
    case 'address you entered is already in use. Please sign up with a different email address.':
      return "Dashboard.OrganizationDashboard.Users.AddUser.Error";
    case 'Button style cannot be created':
      return "Dashboard.Setting.Eline.Buttons.Style.ButtonStyle.Error.Message";
    case "can't be blank":
      return "Dashboard.Setting.Eline.Buttons.Style.ButtonStyle.Error.NameField";
    case "name":
      return "Dashboard.Setting.Eline.Buttons.Style.ButtonStyle.Error.NameValue";
    case 'txtIcon':
      return "Dashboard.Setting.Eline.Buttons.Style.ButtonStyle.Error.IconValue";
    case 'size_error':
      return "Dashboard.OrganizationDashboard.Users.AddUser.Error.SizeError";
    case 'used_slug':
        return "Dashboard.OrganizationDashboard.Users.AddUser.Error.UsedSlug";
    case 'You cannot add any more users':
      return "Dashboard.OrganizationDashboard.Users.AddUser.Error.AddUserError";
    case 'remote_user_error':
      return "Dashboard.OrganizationDashboard.Users.AddUser.Error.AddUserError";
    case 'Import User File Error':
      return "Dashboard.OrganizationDashboard.Users.ImportUser.ErrorFileUpload";
    case 'Add Team Line Error':
      return "Dashboard.OrganizationDashboard.Teams.UpdateGroupLines.Error.AddTimeLine";
    case 'Restore team error':
      return 'Dashboard.OrganizationDashboard.Teams.Revoke.Error'
    default:
      return "";
  }

}

export const updateUserData = (prop , value) => {
  const localData = JSON.parse(localStorage.getItem('userData')) || {}
  localData[prop] = value;
  localStorage.setItem('userData', JSON.stringify(localData))
}

export const useEffectOnce = ( effect )=> {

  const destroyFunc = useRef();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [val, setVal] = useState(0);

  if (effectCalled.current) {
      renderAfterCalled.current = true;
  }

  useEffect( ()=> {

      if (!effectCalled.current) { 
        destroyFunc.current = effect();
        effectCalled.current = true;
      }

      setVal(val => val + 1);

      return ()=> {

        if (!renderAfterCalled.current) { return; }
        if (destroyFunc.current) { destroyFunc.current(); }
      };
  }, []);
};

export const getTrialDays = (days) => {
  return new Date().setDate(new Date().getDate() + days) / 1000
}

export function resizeImage(base64image: any, width: number = 1920, height: number = 1080): Promise<string> {

  // In order to work on Firefox browser we need to handle the asynchronous nature of image loading;  We need to use
  // a promise mechanism. The reason why it 'works' without this mechanism in Chrome is actually 'by accident' because
  // the image happens to be in the cache and the browser is able to deliver the uncompressed/decoded image
  // before using the image in the drawImage call.
  return new Promise(resolve => {
      const img = document.createElement('img');

      img.onload = function() {
          // Create an off-screen canvas.
          const canvas = document.createElement('canvas');

          // Set its dimension to target size.
          const context = canvas.getContext('2d');

          canvas.width = width;
          canvas.height = height;

          // Draw source image into the off-screen canvas.
          // TODO: keep aspect ratio and implement object-fit: cover.
          context.drawImage(img, 0, 0, width, height);

          // Encode image to data-uri with base64 version of compressed image.
          resolve(canvas.toDataURL('image/jpeg', 0.5));
      };
      img.src = base64image;
  });
}

export function resizePNGImage(base64image: any, width: number = 1920, height: number = 1080): Promise<string> {

  // In order to work on Firefox browser we need to handle the asynchronous nature of image loading;  We need to use
  // a promise mechanism. The reason why it 'works' without this mechanism in Chrome is actually 'by accident' because
  // the image happens to be in the cache and the browser is able to deliver the uncompressed/decoded image
  // before using the image in the drawImage call.
  return new Promise(resolve => {
      const img = document.createElement('img');

      img.onload = function() {
          // Create an off-screen canvas.
          const canvas = document.createElement('canvas');

          // Set its dimension to target size.
          const context = canvas.getContext('2d');

          canvas.width = width;
          canvas.height = height;

          // Draw source image into the off-screen canvas.
          // TODO: keep aspect ratio and implement object-fit: cover.
          context.drawImage(img, 0, 0, width, height);

          // Encode image to data-uri with base64 version of compressed image.
          resolve(canvas.toDataURL('image/png', 0.5));
      };
      img.src = base64image;
  });
}