// ** Initial State
const initialState = {
    categories: [],
    subCategories: [],
    onlyAiProduct: null
}

const othersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORIES':
            return {
                ...state,
                categories: action.data
            }
        case 'GET_SUBCATEGORIES':
            return {
                ...state,
                subCategories: action.data
            }
        case 'GET_TIMEZONES':
            return {
                ...state,
                timeZone: action.data
            }
        case 'ONLY_AI_PRODUCT':
            return {
                ...state,
                onlyAiProduct: action.data
            }
        default:
            return state
    }
}

export default othersReducer
