// ** Initial State
const initialState = { data: {} };

const registerInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_REGISTER_INFO':
      return {
        ...state,
        data: action.data,
      };
    case 'CHECK_EMAIL_ACCOUNT':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default registerInfoReducer;
