// ** Initial State
const initialState = []
const blacklistReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BLACK_LIST':
            return action.data
        default:
            return state
    }
}

export default blacklistReducer
