// ** Initial State
const initialState = { blockedCallers: {} }

const blockedCallerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_BLOCKED_CALLERS':
            return {
                ...state,
                blockedCallers: action.data
            }
        default:
            return state
    }
}

export default blockedCallerReducer;