// ** Initial State
const initialState = {
    history: null,
    buttonViews: null,
    trackerViews: null,
    dataLoading: true,
    dataError: false,
    historyUser: null,
    historyMissed: null,
    userMetrics: []
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WELCOME_ITEMS':
            return {
                history: action.data.history,
                buttonViews: action.data.buttonViews,
                trackerViews: action.data.trackerClicks,
                dataLoading: false,
                dataError: false,
            }
        case 'GET_WELCOME_ITEMS_ERROR':
            return {
                history: null,
                buttonViews: null,
                trackerViews: null,
                dataError: true,
                dataLoading: false,
            }
        case 'GET_HOME_ITEMS':
            return {
                dataLoading: false,
                dataError: false,
                historyUser: action.data.historyUser,
                historyMissed: action.data.historyMissed,
                userMetrics: action.data.userMetrics
            }
        case 'GET_HOME_ITEMS_ERROR':
            return {
                dataError: true,
                dataLoading: false,
                historyUser: null,
                historyMissed: null,
                userMetrics: null
            }
        default:
            return state
    }
}

export default homeReducer
