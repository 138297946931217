// ** Initial State
const initialState = {
    trackers: [],
    short_urls: [],
    buttons: [],
    buttonItem: {},
    eline: {},
    headers: {},
    greetings: []
}
const settingsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'GET_TRACKERS':
            return {
                ...state,
                trackers: action.data
            }
        case 'GET_ELINE_CONF':
            return {
                ...state,
                eline: action.data
            }
        case 'GET_TRACKERS_ITEM':
            return {
                ...state,
                trackers: action.data
            }
        case 'GET_SHORT_URL':
            return {
                ...state,
                short_urls: action.data
            }
        case 'POST_TRACKERS':
            return {
                ...state,
                trackers: action.data
            }
        case 'DELETE_TRACKERS':
            return {
                ...state,
                trackers: state.trackers.filter( i => i.id !== action.id )
            }
        case 'GET_BUTTONS':
            return {
                ...state,
                buttons: action.data
            }
        case 'POST_BUTTONS':
            return {
                ...state,
                buttons: action.data,
                headers: action.headers
            }
        case 'GET_BUTTON_ITEM':
            return {
                ...state,
                buttonItem: action.data
            }
        case 'DELETE_BUTTONS':
            return {
                ...state,
                buttons: state.buttons.filter( i => i.id !== action.id )
            }
        case 'GET_GREETINGS':
            return {
                ...state,
                greetings: action.data
            }
        case 'UPDATE_GREETINGS':
            return {
                ...state,
                greetings: state.greetings.map( greeting => greeting.id === action.data.id ? action.data : greeting )
            }
        default:
            return state
    }
}

export default settingsReducer
