// ** Initial State
const initialState = []
const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CONTACT_ITEMS':
            return action.data
        default:
            return state
    }
}

export default contactReducer
